<div id="main-view">
  <r7-progress-bar></r7-progress-bar>
  <div class="web-container" *ngIf="(auth.isAuthenticated$ | async) === false; else appView">
    <div class="login-container">
      <img class="splash-image" src="../assets/images/splash.png">

      <div class="app-title-container">
        <h1 class="app-title">Realtime Pub/Sub</h1>

        <div class="subtitle-container">
          <h4>{{ 'COMMON.APP_HOME_DESCRIPTION' | translate }}</h4>
        </div>
      </div>
      <div class="buttons-container">
        <div>
          <button class="get-started primary-button" mat-stroked-button color="info" (click)="loginWithRedirect()">
            <mat-icon fontIcon="celebration"></mat-icon>
            {{ 'COMMON.GET_STARTED' | translate }}</button>
        </div>
      </div>
      <div class="conditions">
        <span>By signing up, I agree to the <a href="https://realtime.21no.de/terms/" target="r7-docs">Terms of Use</a>
          and <a href="https://realtime.21no.de/privacy-policy/" target="r7-docs">Privacy Policy</a></span>
      </div>
    </div>
  </div>

  <ng-template #appView>
    <div class="web-container">
      <div class="app-container">
        <div class="left-sidebar">
          <div class="app-image-container" routerLink="/applications">
            <img routerLink="/" src="../assets/images/splash.png" mat-card-avatar class="app-image" alt="Home Link" />
            <span>Realtime Pub/Sub</span>
          </div>
          <mat-sidenav #snav [opened]="openSideNav" [mode]="mobileQuery.matches ? 'over' : 'side'"
            [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
            <mat-nav-list>
              <a mat-list-item routerLink="/applications">
                <div class="menu-item">
                  <mat-icon>grid_view</mat-icon>
                  <span>{{ 'SIDE_NAV.APPLICATIONS' | translate }}</span>
                </div>
              </a>
              <a mat-list-item href="https://github.com/BackendStack21/r7-forum" target="_blank">
                <div class="menu-item">
                  <mat-icon>support_agent</mat-icon>
                  <span>{{ 'SIDE_NAV.SUPPORT' | translate }}</span>
                </div>
              </a>
              <a mat-list-item href="https://status.realtime.21no.de" target="_blank">
                <div class="menu-item">
                  <mat-icon>heart_broken</mat-icon>
                  <span>{{ 'SIDE_NAV.STATUS_PAGE' | translate }}</span>
                </div>
              </a>
              <a mat-list-item href="https://latency.r7.21no.de" target="_blank">
                <div class="menu-item">
                  <mat-icon>speed</mat-icon>
                  <span>{{ 'SIDE_NAV.LATENCY_BENCH_PAGE' | translate }}</span>
                </div>
              </a>
            </mat-nav-list>
          </mat-sidenav>
          <r7-plan-usage></r7-plan-usage>
        </div>
        <div class="main-content">
          <div class="header-row">
            <div class="username-container">
              <div class="username-right-sidebar">
                <div *ngIf="auth.user$ | async as user" class="header-container">
                  <div class="user-details">
                    <img [src]="user.picture" mat-card-avatar class="user-image" alt="Profile picture" />
                    <span class="username">{{ (user?.name || '') | truncate: [30, '...'] }} </span>
                  </div>
                  <div class="logout">
                    <button class="settings" mat-icon-button aria-label="Settings" routerLink="/account-settings">
                      <mat-icon>tune</mat-icon>
                    </button>
                    <button (click)="logout()" mat-icon-button aria-label="Logout">
                      <mat-icon>logout</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="router-outlet">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
    <r7-footer></r7-footer>
  </ng-template>
</div>
