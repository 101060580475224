import { EventEmitter, Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class AppService {

  key = 'cookiesAccepted'
  profileSyncedEmitter = new EventEmitter<boolean>()
  profileSynced = false

  getConsent(): boolean {
    return sessionStorage.getItem(this.key) === 'true'
  }

  acceptConsent(): void {
    sessionStorage.setItem(this.key, 'true')
  }
}
