import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core'
import { CommonModule, DOCUMENT } from '@angular/common'
import { AuthService } from '@auth0/auth0-angular'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatIconModule } from '@angular/material/icon'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatMenuModule } from '@angular/material/menu'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { Subscription } from 'rxjs'
import { Store } from '@ngxs/store'
import { EmitterService } from '@ngxs-labs/emitter'
import { AppState, AppStateModel } from 'src/app/@states/app.state'
import { StateClear } from 'ngxs-reset-plugin'
import { Router, RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { MatProgressBarModule } from '@angular/material/progress-bar'

@Component({
  selector: 'r7-app-header',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressBarModule
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Output() navToggled = new EventEmitter<void>()

  subscription = new Subscription()

  constructor(public auth: AuthService,
    private store: Store,
    private router: Router,
    private emitterService: EmitterService,
    private cd: ChangeDetectorRef,
    public translate: TranslateService,
    @Inject(DOCUMENT) private doc: Document) {
    translate.addLangs(['en', 'de'])
    translate.setDefaultLang('en')

    const browserLang = translate.getBrowserLang() as string
    translate.use(browserLang.match(/en|de/) ? browserLang : 'en')
  }

  async ngOnInit() {
    this.cd.detectChanges()

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const user$ = this.auth.user$.subscribe(async (user: any) => {
      if (user) {
        const payload: AppStateModel = {
          isAuthenticated: true,
          user
        }
        this.cd.detectChanges()
        this.emitterService.action<AppStateModel>(AppState.setAuthDetails).emit(payload)
        this.router.navigate(['applications'])
      }
    })
    this.subscription.add(user$)
  }

  loginWithRedirect() {
    this.auth.loginWithRedirect()
  }

  logout() {
    this.store.dispatch(new StateClear())
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
