import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AppDetailResolver } from './@pages/applications/app-details/app-detail.resolver'

const routes: Routes = [
  {
    path: 'applications',
    loadComponent: () => import('./@pages/applications/applications.component').then(m => m.ApplicationsComponent)
  },
  {
    path: 'applications/create-app/:id',
    loadComponent: () => import('./@pages/create-app/create-app.component').then(m => m.CreateAppComponent),
    resolve: {
      appDetails: AppDetailResolver
    }
  },
  {
    path: 'applications/app-details/:id',
    loadComponent: () => import('./@pages/applications/app-details/app-details.component').then(m => m.AppDetailsComponent),
    resolve: {
      appDetails: AppDetailResolver
    }
  },
  {
    path: 'applications/app-details/:id/logs',
    loadComponent: () => import('./@pages/applications/logs/logs.component').then(m => m.LogsComponent),
    resolve: {
      appDetails: AppDetailResolver
    }
  },

  {
    path: 'websocket-client/:id',
    loadComponent: () => import('./@pages/websocket-client/websocket-client.component').then(m => m.WebSocketClientComponent),
    resolve: {
      appDetails: AppDetailResolver
    }
  },
  {
    path: 'support-center',
    loadComponent: () => import('./@pages/support-center/support-center.component').then(m => m.SupportCenterComponent)
  },
  {
    path: 'account-settings',
    loadComponent: () => import('./@pages/account-settings/account-settings.component').then(m => m.AccountSettingsComponent)
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
