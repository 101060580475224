<mat-toolbar color="basic" class="container">
    <mat-toolbar-row>
        <!-- <div *ngIf="auth.user$ | async as user">
            <button mat-icon-button (click)="navToggled.emit()"><mat-icon>menu</mat-icon></button>
        </div> -->
        <img routerLink="/" src="../assets/images/splash.png" mat-card-avatar class="app-image" alt="Profile picture" />

        <span>Realtime Pub/Sub</span>
        <span class="example-spacer"></span>

        <button *ngIf="(auth.isAuthenticated$ | async) === false" (click)="loginWithRedirect()" mat-button
            color="secondary" aria-label="Example icon-button with heart icon">
            {{ 'COMMON.LOGIN' | translate }}
        </button>

        <div *ngIf="auth.user$ | async as user" class="container">
            <button mat-icon-button aria-label="Menu">
                <img [src]="user.picture" mat-card-avatar class="user-image" alt="Profile picture" />
            </button>
            <span class="username">{{ user?.name }} </span>
            <button mat-icon-button aria-label="Settings" routerLink="/account-settings">
                <mat-icon>tune</mat-icon>
            </button>
            <button (click)="logout()" mat-icon-button aria-label="Logout">
                <mat-icon>logout</mat-icon>
            </button>
        </div>
    </mat-toolbar-row>
</mat-toolbar>