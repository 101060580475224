import { CONFIG } from "auth_config"

const {
  domain,
  clientId,
  authorizationParams: { audience },
  dev: { apiUri, wsUri },
  errorPath
} = CONFIG

export const environment = {
  production: false,
  auth: {
    domain,
    clientId,
    authorizationParams: {
      ...(audience ? { audience } : null),
      redirect_uri: window.location.origin,
    },
    errorPath,
  },
  httpInterceptor: {
    allowedList: [`${apiUri}/*`],
  },
  apiUri,
  wsUri
}
