import { State, StateContext } from '@ngxs/store'
import { EmitterAction, Receiver } from '@ngxs-labs/emitter'
import { NewAppPayload } from '../@models/new-app.payload.model'

@State<NewAppPayload>({
  name: 'newApp',
  defaults: {}
})
export class NewAppState {

  @Receiver()
  static setAppDetails(
    { setState, getState }: StateContext<NewAppPayload>,
    { payload }: EmitterAction<NewAppPayload>) {
    const newState = { ...getState(), ...payload }
    setState(newState)
  }

  @Receiver()
  static reset(
    { setState }: StateContext<NewAppPayload>) {
    setState({})
  }
}
