import { Injectable, inject } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { Observable, of } from 'rxjs'
import { AppEntryAggregated } from 'src/app/@models/apps.model'
import { ApiService } from 'src/app/@services/api.service'
import { DataService } from 'src/app/@services/data.service'

@Injectable({
  providedIn: 'root'
})
export class AppDetailResolver  {

  apiService = inject(ApiService)
  dataService = inject(DataService)

  resolve(route: ActivatedRouteSnapshot): Observable<AppEntryAggregated | null> {
    const appId = route.paramMap.get('id') as string
    if (appId) {
      return this.apiService.getAppDetails(appId)
    } else {
      // return this.dataService.getFirstApp();
      return of(null)
    }
  }
}
