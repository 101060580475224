import { environment } from "src/environments/environment"

export const ENDPOINTS = {
  // getApps: (pageSize: number = 10) => CONFIG.apiUri + `/apps-manager/apps?limit=${pageSize}`,
  getApps: () => environment.apiUri + `/octopus/apps`,

  createApp: () => environment.apiUri + `/apps-manager/apps`,
  updateApp: (appId: string) => environment.apiUri + `/apps-manager/apps/${appId}`,
  deleteApp: (appId: string) => environment.apiUri + `/apps-manager/apps/${appId}`,
  getAppDetails: (appId: string) => environment.apiUri + `/apps-manager/apps/${appId}`,
  generateHsToken: (appId: string, type = 'client') => environment.apiUri + `/apps-manager/apps/${appId}/generate-hs-token?type=${type}`,

  getLogs: (appId: string, queryParams: string) => environment.apiUri + `/telemetry/logs/publications/${appId}?${queryParams}`,
  getAccountMonthlyUsage: () => environment.apiUri + `/telemetry/account/monthly-usage`,
  getAccountHistoricalUsage: () => environment.apiUri + `/telemetry/account/historical-usage`,
  downloadPublicationsLogsPastDay: (appId: string) => environment.apiUri + `/telemetry/logs/download/publications/${appId}/past-day`,
  getPublicationsTelemetry: (appId: string, days: number) => environment.apiUri + `/telemetry/publications/${appId}/${days}`,
  getAggregatedTelemetry: (appId: string, days: number) => environment.apiUri + `/telemetry/aggregates/${appId}/${days}`,
  getCountryConnectionsTelemetry: (appId: string, days: number) => environment.apiUri + `/telemetry/heatmap/connections/countries/${appId}/${days}`,
  getHotTopicsTelemetry: (appId: string, days: number) => environment.apiUri + `/telemetry/top-topics/${appId}/${days}`,

  profile: () => environment.apiUri + `/settings/profile`,
  profileSync: () => environment.apiUri + `/settings/profile-sync`,
  requestAccount2FA: () => environment.apiUri + `/settings/request-account-2fa`,
  confirmProfileChange: () => environment.apiUri + `/settings/confirm-profile-change`,
  confirmDownloadPersonalData: () => environment.apiUri + `/settings/confirm-download-personal-data`,
}
