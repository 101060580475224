<div class="container">
  <h4>Plan usage</h4>
  <!-- Connection progress bar -->
  <div>
    <label for="connectionsProgressBar">Connections: {{usedConnections}} / {{maxConnections}}</label>
    <br>
    <progress id="connectionsProgressBar" value="{{usedConnections}}" max="{{maxConnections}}"></progress>
  </div>

  <!-- Bytes used progress bar -->
  <div>
    <label for="bytesProgressBar">Egress Traffic: {{usedBytes | bytesSize}} / {{maxBytes | bytesSize}}</label>
    <br>
    <progress id="bytesProgressBar" value="{{usedBytes}}" max="{{maxBytes}}"></progress>
  </div>
</div>
