export const APP_CONFIG = {
  LOGS_PAGINATION: 100,
  PUBLICATION_DAYS: 1,

  DOCS_GETTING_STARTED: 'https://realtime.21no.de/getting-started/',
  DOCS_SECURITY_CONSIDERATIONS: 'https://realtime.21no.de/documentation/#embrace-public-key-authentication-with-jwt',
  DOCS_RESTRICTING_ACCESS_TO_ENDPOINTS: 'https://realtime.21no.de/getting-started/#restricting-access-to-your-application-endpoints',
  DOCS_JWT_INTRO: 'https://realtime.21no.de/getting-started/#setting-up-an-identify-provider-idp-for-jwt-based-authentication',

  REFRESH_INTERVAL: 30000
}
