import { DOCUMENT } from '@angular/common'
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, inject } from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
import { EmitterService } from '@ngxs-labs/emitter'
import { Store } from '@ngxs/store'
import { Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { MediaMatcher } from '@angular/cdk/layout'
import { StateClear } from 'ngxs-reset-plugin'
import { AppState, AppStateModel } from './@states/app.state'
import { Router } from '@angular/router'
import { AppService } from './@services/app.service'
import { ApiService } from './@services/api.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DialogService } from './@services/dialog.service'
import { ProgressBarService } from './@services/progress-bar.service'

@Component({
  selector: 'r7-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'r7-console'

  mobileQuery!: MediaQueryList
  private _mobileQueryListener: () => void

  working = false
  openSideNav = false
  idToken = ''
  subscription = new Subscription()
  isAuthenticated = false

  appService = inject(AppService)
  apiService = inject(ApiService)
  auth0Service = inject(AuthService)
  snackBar = inject(MatSnackBar)
  dialogService = inject(DialogService)

  constructor(
    public auth: AuthService,
    private cd: ChangeDetectorRef,
    private store: Store,
    private router: Router,
    private emitterService: EmitterService,
    changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    @Inject(DOCUMENT) private doc: Document,
    media: MediaMatcher,
    private progressBarService: ProgressBarService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en')

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en')

    this.mobileQuery = media.matchMedia('(max-width: 600px)')
    this._mobileQueryListener = () => changeDetectorRef.detectChanges()
    this.mobileQuery.addListener(this._mobileQueryListener)
  }

  async ngOnInit() {
    this.subscription.add(this.auth.isAuthenticated$.subscribe(async (isAuthenticated: boolean) => {
      this.openSideNav = isAuthenticated
      this.isAuthenticated = isAuthenticated

      if (isAuthenticated) {
        this.progressBarService.show()
        this.working = true

        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const user$ = this.auth.user$.subscribe(async (user: any) => {
          if (user) {
            const payload: AppStateModel = {
              isAuthenticated: true,
              user
            }
            this.profileSync()

            this.working = false

            this.emitterService.action<AppStateModel>(AppState.setAuthDetails).emit(payload)
            this.router.navigate(['applications'])
          }
        })
        this.subscription.add(user$)
      }
    }))
  }

  // @TODO: Refactor this code
  private profileSync(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this

    if (this.isAuthenticated) {
      this.appService.profileSyncedEmitter.subscribe(() => {
        self.subscription.add(
          self.apiService.getProfile().subscribe((res) => {
            self.translate.use(res.consoleLang)
            this.progressBarService.hide()

            self.cd.detectChanges()
          }, (err) => {
            self.dialogService.showError(err.message)
          })
        )
      })
      this.subscription.add(
        this.apiService.profileSync().subscribe(() => {
          self.appService.profileSynced = true
          self.appService.profileSyncedEmitter.emit(true)

          self.cd.detectChanges()
        }, (err) => {
          self.dialogService.showError(err.message)
        })
      )
    }
  }

  loginWithRedirect() {
    this.auth.loginWithRedirect()
  }

  logout() {
    this.store.dispatch(new StateClear())
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } })
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener)
    this.subscription.unsubscribe()
  }
}
