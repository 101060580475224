<div class="header-row-container">
    <div class="title">
        <mat-icon color="accent">info</mat-icon>
        <h3 mat-dialog-title>{{ title | translate }}</h3>
    </div>
    <button mat-icon-button aria-label="Close" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <div class="text">
        {{ message }}
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button class="secondary-button" [mat-dialog-close]="true">
        <mat-icon fontIcon="block"></mat-icon>
        {{'COMMON.CLOSE' | translate }}</button>
</mat-dialog-actions>