<div class="footer-container">
  <div class="app-container">
    <div class="main-content">
      <div class="row">

        <div class="connect">
          <div class="link-header">{{ 'COMMON.FOOTER_CONNECT' | translate }}</div>
          <div class="link-text">
            <a href="https://medium.com/@kyberneees/list/realtime-pubsub-72ca07c13d3e" target="r7-medium">Medium</a>
          </div>
          <div class="link-text">
            <a href="mailto:realtime.contact@21no.de">{{ 'COMMON.FOOTER_EMAIL_US' | translate }}</a>
          </div>
        </div>
        <div class="resources">
          <div class="link-header">{{ 'COMMON.FOOTER_RESOURCES' | translate }}</div>
          <div class="link-text">
            <a href="https://realtime.21no.de/terms" target="r7-docs">{{ 'COMMON.FOOTER_TERMS_OF_USE' | translate }}</a>
          </div>

          <div class="link-text">
            <a href="https://realtime.21no.de/faq" target="r7-docs">{{ 'COMMON.FOOTER_FAQ' | translate }}</a>
          </div>
          <div class="link-text">
            <a href="https://realtime.21no.de/privacy-policy" target="r7-docs">{{ 'COMMON.FOOTER_PRIVACY_POLICY' | translate
              }}</a>
          </div>
          <div class="link-text">
            <a href="https://realtime.21no.de/introduction/" target="r7-docs">{{ 'COMMON.FOOTER_DOCUMENTATION' | translate }}</a>
          </div>
        </div>
        <div class="about">
          <div class="link-header">{{ 'COMMON.FOOTER_ABOUT' | translate }}</div>
          <div class="link-text">
            <a href="https://realtime.21no.de" target="r7-website">{{ 'COMMON.FOOTER_WEBSITE' | translate }}</a>
          </div>
          <!--
                    <div class="link-text">
                        <a>{{ 'COMMON.FOOTER_OUR_STORY' | translate }}</a>
                    </div>
                    <div class="link-text">
                        <a>{{ 'COMMON.FOOTER_PRESS' | translate }}</a>
                    </div>
                    -->
        </div>
      </div>
      <div class="row">
        <span class="link-text">2023 <span>&copy;</span> {{ 'COMMON.FOOTER_ALL_RIGHTS_RESERVED' | translate }} - <a
            href="https://21no.de" target="_blank">21no.de</a>
        </span>
      </div>
      <div class="row">
        <div class="version-text">Build Date: {{ buildDate }}, Version: {{ commitHash }}</div>
      </div>
    </div>
  </div>
</div>
