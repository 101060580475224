import { Component, OnInit } from '@angular/core'
import { ProgressBarService } from '../../@services/progress-bar.service'

@Component({
  selector: 'r7-progress-bar',
  template: `
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
  `,
  styles: [`
    mat-progress-bar {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
    }
  `]
})
export class ProgressBarComponent implements OnInit {
  isLoading = false

  constructor(private progressBarService: ProgressBarService) { }

  ngOnInit(): void {
    this.progressBarService.loadingStatus.subscribe((status: boolean) => {
      this.isLoading = status
    })
  }
}
