import { ChangeDetectionStrategy, Component } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { VERSION } from 'src/environments/version'

@Component({
  selector: 'r7-footer',
  standalone: true,
  imports: [
    TranslateModule
  ],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  buildDate = VERSION.buildDate
  commitHash = VERSION.buildVersion
}
