export const CONFIG = {
  domain: "21node.eu.auth0.com",
  clientId: "lSyfp1aNFIxcFFRReneOJHj9rSXn18LQ",
  authorizationParams: {
    audience: "api-gateway.r7.21no.de"
  },

  dev: {
    apiUri: "https://dev-api-gateway.r7.21no.de",
    wsUri: "wss://dev-genesis.r7.21no.de",
  },
  prod: {
    apiUri: "https://api-gateway.r7.21no.de",
    wsUri: "wss://genesis.r7.21no.de",
  },

  errorPath: "/error"
}
