import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatTooltipModule } from '@angular/material/tooltip'
import { BytesSizePipe } from 'src/app/@pipes/bytes-size.pipe'
import { APP_CONFIG } from 'src/app/@config/app.config'
import { Subscription } from 'rxjs'
import { ApiService } from 'src/app/@services/api.service'
import { AccountMonthlyUsage } from 'src/app/@models/aggregate-metric.model'

@Component({
  selector: 'r7-plan-usage',
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    BytesSizePipe
  ],
  templateUrl: './plan-usage.component.html',
  styleUrls: ['./plan-usage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanUsageComponent implements OnInit, AfterViewInit, OnDestroy {
  private interval?: NodeJS.Timeout
  subscription = new Subscription()

  cd = inject(ChangeDetectorRef)
  usedConnections = 0
  usedBytes = 0
  maxConnections = 0
  maxBytes = 0

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.getMetrics()
  }

  ngAfterViewInit(): void {
    this.interval = setInterval(() => {
      this.refresh()
    }, APP_CONFIG.REFRESH_INTERVAL)
  }

  private refresh(): void {
    this.getMetrics()
  }

  getMetrics(): void {
    this.subscription.add(
      this.apiService.getAccountMonthlyUsage().subscribe((usage: AccountMonthlyUsage) => {
        this.usedConnections = usage.activeConnections
        this.usedBytes = usage.egressTraffic

        this.apiService.getProfile().subscribe((profile) => {
          const plan = profile.plan // example: 300-50 (300 connections and 50GB)

          this.maxConnections = parseInt(plan.split('-')[0], 10)
          this.maxBytes = parseInt(plan.split('-')[1], 10) * 1024 * 1024 * 1024

          this.cd.detectChanges()
        })
      }))
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()

    if (this.interval) {
      clearInterval(this.interval)
    }
  }

}
