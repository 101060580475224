
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NgxsModule } from '@ngxs/store'
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin'
import { NgxsConfig } from '@ngxs/store/src/symbols'
import { NgxsLoggerPluginOptions } from '@ngxs/logger-plugin/src/symbols'
import { NgxsEmitPluginModule } from '@ngxs-labs/emitter'
import { NgxsResetPluginModule } from 'ngxs-reset-plugin'
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin'
import { NgxsDevtoolsOptions } from '@ngxs/devtools-plugin/src/symbols'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { ALL_STATES } from './all-states'
import { environment } from 'src/environments/environment'

export const OPTIONS_CONFIG: Partial<NgxsConfig> = {
  /**
   * Run in development mode. This will add additional debugging features:
   * - Object.freeze on the state and actions to guarantee immutability
   * todo: you need set production mode
   * import { environment } from '@env';
   * developmentMode: !environment.production
   */
  developmentMode: !environment.production
}

export const DEVTOOLS_REDUX_CONFIG: NgxsDevtoolsOptions = {
  /**
   * Whether the dev tools is enabled or note. Useful for setting during production.
   * todo: you need set production mode
   * import { environment } from '@env';
   * disabled: environment.production
   */
  disabled: environment.production
}

export const LOGGER_CONFIG: NgxsLoggerPluginOptions = {
  /**
   * Disable the logger. Useful for prod mode..
   * todo: you need set production mode
   * import { environment } from '@env';
   * disabled: environment.production
   */
  disabled: environment.production
}

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forRoot(
      [
        ...ALL_STATES
      ], OPTIONS_CONFIG),
    NgxsReduxDevtoolsPluginModule.forRoot(DEVTOOLS_REDUX_CONFIG),
    NgxsLoggerPluginModule.forRoot(LOGGER_CONFIG),
    NgxsEmitPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [...ALL_STATES]
    })
  ],
  providers: [],
  exports: [NgxsModule]
})
export class NgxsStoreModule { }
