import { Pipe, PipeTransform } from '@angular/core'

// Limit units to PB as the largest size
const FILE_SIZE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
const FILE_SIZE_UNITS_LONG = ['Bytes', 'Kilobytes', 'Megabytes', 'Gigabytes', 'Terabytes', 'Pettabytes']

@Pipe({
  name: 'bytesSize',
  standalone: true
})
export class BytesSizePipe implements PipeTransform {
  transform(sizeInBytes: number, longForm = false): string {
    if (!sizeInBytes) {
      return `0 ${FILE_SIZE_UNITS[0]}`
    }

    const units = longForm ? FILE_SIZE_UNITS_LONG : FILE_SIZE_UNITS
    // Calculate power based on the size in bytes; limit to the length of the units array
    const power = Math.min(Math.floor(Math.log(sizeInBytes) / Math.log(1024)), units.length - 1)

    // Calculate the size in the selected unit
    const size = sizeInBytes / Math.pow(1024, power)

    // Format the size with up to 2 decimal places, only if necessary
    const formattedSize = this.formatNumber(size)
    const unit = units[power]

    return `${formattedSize} ${unit}`
  }

  private formatNumber(number: number): string {
    // Check if the number is an integer and doesn't need decimals
    if (Math.floor(number) === number) {
      return number.toString()
    }
    // Limit to 2 decimal places for non-integer numbers
    return number.toFixed(2)
  }
}
