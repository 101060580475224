import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, retry } from 'rxjs'
import { ENDPOINTS } from '../@shared/endpoints'
import { AppEntryAggregated, Apps, Countries, GenerateHsTokenResponse } from '../@models/apps.model'
import { NewAppPayload } from '../@models/new-app.payload.model'
import { AppLogs } from '../@models/app-log.model'
import { APP_CONFIG } from '../@config/app.config'
import { PublicationTelemetry } from '../@models/publication-telemetry.model'
import { AccountMonthlyUsage, AccountHistoricalUsage, AggregateMetrics } from '../@models/aggregate-metric.model'
import { Account2FARequestPayload, Account2FARequestResponse } from '../@models/account-2fa-request.model'
import { ConfirmProfileChangeResponse, ConfirmationCodeRequest, Profile, ProfileUpdateRequest } from '../@models/settings.model'

const MAX_RETRIES = 10

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  getApps(): Observable<Apps> {
    return this.http.get<Apps>(ENDPOINTS.getApps())
      .pipe(retry(MAX_RETRIES))
  }

  getAppDetails(appId: string): Observable<AppEntryAggregated> {
    return this.http.get<AppEntryAggregated>(ENDPOINTS.getAppDetails(appId))
      .pipe(retry(MAX_RETRIES))
  }

  deleteApp(appId: string): Observable<unknown> {
    return this.http.delete(ENDPOINTS.deleteApp(appId))
  }

  createApp(payload: NewAppPayload): Observable<unknown> {
    return this.http.post(ENDPOINTS.createApp(), payload)
  }

  updateApp(appId: string, payload: NewAppPayload): Observable<AppEntryAggregated> {
    return this.http.put<AppEntryAggregated>(ENDPOINTS.updateApp(appId), payload)
  }

  getLogs(appId: string, offset: string | null = null, limit: number = APP_CONFIG.LOGS_PAGINATION, direction: 'prev' | 'next' = 'next'): Observable<AppLogs> {
    let queryParams = `limit=${limit}`

    if (offset) {
      queryParams += `&offset=${offset}&direction=${direction}`
    }

    return this.http.get<AppLogs>(ENDPOINTS.getLogs(appId, queryParams))
      .pipe(retry(MAX_RETRIES))
  }

  getAccountMonthlyUsage(): Observable<AccountMonthlyUsage> {
    return this.http.get<AccountMonthlyUsage>(ENDPOINTS.getAccountMonthlyUsage())
      .pipe(retry(MAX_RETRIES))
  }

  getAccountHistoricalUsage(): Observable<AccountHistoricalUsage[]> {
    return this.http.get<AccountHistoricalUsage[]>(ENDPOINTS.getAccountHistoricalUsage())
      .pipe(retry(MAX_RETRIES))
  }

  downloadPublicationsLogsPastDay(appId: string): Observable<Blob> {
    return this.http.get(ENDPOINTS.downloadPublicationsLogsPastDay(appId), { responseType: 'blob' })
  }

  getPublicationTelemetry(appId: string, days: number): Observable<PublicationTelemetry> {
    return this.http.get<PublicationTelemetry>(ENDPOINTS.getPublicationsTelemetry(appId, days))
      .pipe(retry(MAX_RETRIES))
  }

  getAggregateTelemetry(appId: string, days: number): Observable<AggregateMetrics> {
    return this.http.get<AggregateMetrics>(ENDPOINTS.getAggregatedTelemetry(appId, days))
      .pipe(retry(MAX_RETRIES))
  }

  getCountryConnectionTelemetry(appId: string, days: number): Observable<Countries> {
    return this.http.get<Countries>(ENDPOINTS.getCountryConnectionsTelemetry(appId, days))
      .pipe(retry(MAX_RETRIES))
  }

  getHotTopics(appId: string, days: number): Observable<PublicationTelemetry> {
    return this.http.get<PublicationTelemetry>(ENDPOINTS.getHotTopicsTelemetry(appId, days))
      .pipe(retry(MAX_RETRIES))
  }

  generateHsToken(appId: string, type: string): Observable<GenerateHsTokenResponse> {
    return this.http.post<GenerateHsTokenResponse>(ENDPOINTS.generateHsToken(appId, type), {})
  }

  requestAccount2FA(payload: Account2FARequestPayload): Observable<Account2FARequestResponse> {
    return this.http.post<Account2FARequestResponse>(ENDPOINTS.requestAccount2FA(), payload)
  }

  confirmProfileChange(confirmationCode: ConfirmationCodeRequest): Observable<ConfirmProfileChangeResponse> {
    return this.http.post<ConfirmProfileChangeResponse>(ENDPOINTS.confirmProfileChange(), confirmationCode)
  }

  confirmDownloadPii(confirmationCode: ConfirmationCodeRequest): Observable<Blob> {
    return this.http.post(ENDPOINTS.confirmDownloadPersonalData(), confirmationCode, {
      responseType: 'blob'
    })
  }

  profileSync(): Observable<unknown> {
    return this.http.post(ENDPOINTS.profileSync(), {})
      .pipe(retry(MAX_RETRIES))
  }

  profileUpdate(payload: ProfileUpdateRequest): Observable<unknown> {
    return this.http.put(ENDPOINTS.profile(), payload)
      .pipe(retry(MAX_RETRIES))
  }

  getProfile(): Observable<Profile> {
    return this.http.get<Profile>(ENDPOINTS.profile())
      .pipe(retry(MAX_RETRIES))
  }
}

