import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { DialogComponent } from '../@components/dialog/dialog.component'

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) { }

  showError(message: string): void {
    document.getElementById('main-view')?.classList.add('hidden-component')

    this.dialog.open(DialogComponent, {
      data: message,
      width: '475px'
    }).afterClosed().subscribe(() => {
      document.getElementById('main-view')?.classList.remove('hidden-component')
    })
  }
}
