import { State, StateContext } from '@ngxs/store'
import { EmitterAction, Receiver } from '@ngxs-labs/emitter'
import { AuthUser } from '../@models/app-state.model'

export interface AppStateModel {
  isAuthenticated?: boolean;
  user?: AuthUser;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {}
})
export class AppState {

  @Receiver()
  static setAuthDetails(
    { setState, getState }: StateContext<AppStateModel>,
    { payload }: EmitterAction<AppStateModel>) {
    const newState = { ...getState(), ...payload }
    setState(newState)
  }
}
