import { Injectable } from '@angular/core'
import { AppEntryAggregated } from '../@models/apps.model'
import { Observable, of } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private appEntries: AppEntryAggregated[] = []

  setAppEntries(apps: AppEntryAggregated[]): void {
    this.appEntries = apps
  }

  getFirstApp(): Observable<AppEntryAggregated> {
    return of(this.appEntries[0])
  }
}
